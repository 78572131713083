import React, { useState, useEffect } from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import styles from './index.module.css'
import SEO from '../components/seo'

const services = ['mobile app', 'web app', 'website']

export default ({ location, data }) => {
  const [serviceState, setServiceState] = useState({
    service: services[0],
    index: 0
  })
  const [typeState, setTypeState] = useState({
    text: '',
    index: 0,
    textToType: serviceState.service
  })

  useEffect(() => {
    console.log('useEffect[typeState]', typeState)
    const currService = serviceState.service
    if (typeState.textToType !== currService) {
      setTypeState({
        text: '',
        index: 0,
        textToType: currService
      })
      return
    }

    if (typeState.index <= currService.length) {
      const delay = setTimeout(() => {
        setTypeState({
          text: typeState.text + currService.charAt(typeState.index),
          index: typeState.index + 1,
          textToType: currService
        })
        clearTimeout(delay)
      }, 100)
    }
  }, [typeState, serviceState.service])

  useEffect(() => {
    console.log('useEffect[serviceState]', serviceState)
    let i = serviceState.index + 1
    if (i >= services.length) {
      i = 0
    }

    const delay = setTimeout(() => {
      setServiceState({
        service: services[i],
        index: i
      })
      // setTypeState({
      //   text: '',
      //   index: 0
      // })
      clearTimeout(delay)
    }, 4000)
  }, [serviceState])

  return (
    <Layout location={location}>
      <SEO title="Home" description="Welcome to Devpedal" />

      <div className={styles.wow}>
        <h2>We help you build your next</h2>
        <h1>
          {typeState.text}
          <span className={styles.cursor}>|</span>
        </h1>
      </div>

      {/* <div style={{ marginTop: rhythm(2) }}>
        <h4>Recent Posts ({data.allMarkdownRemark.edges.length})</h4>
        <ul>
          {data.allMarkdownRemark.edges.map(({ node }) => {
            return (
              <li key={node.id}>
                <Link to={node.fields.slug}>
                  <span className={styles.blogTitle}>
                    {node.frontmatter.title}
                  </span>{' '}
                  <span className={styles.blogDate}>
                    <i>({node.frontmatter.date})</i>
                  </span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div> */}

      <div className={styles.section}>
        <span>Get a free quote! Contact us now.</span>
        <br />
        <Link to="/contact">
          <button className={styles.jumbo}>Contact Us</button>
        </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
